<template>
  <div :style="computedStyle" v-show="!isHidden">
  <span class="registry_fields_label">
    {{ label }}
    <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
      <i class="el-icon-question"></i>
    </el-tooltip>
  </span>
    <el-form-item
      :prop="name"
      :rules="[
      { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
    ]">
    <el-input v-if="!richEditor"  v-bind:value="value"
              type="textarea"
              :autosize="{minRows: minRows || 2, maxRows: minRows || 2}"
              :size="size"
              :placeholder="placeholder || $locale.main.placeholder.string_field"
              v-on:input="$emit('input', $event)" :name="name" :readonly="readonly"></el-input>
      <ckeditor
              :editor="editor"
              ref="editor"
              :disabled="readonly"
              style="border-radius: 4px; border: 1px solid rgb(220, 223, 230);"
              :style="'height:' + height + ';width:' + width"
              v-model="localModel"
              v-else
      ></ckeditor>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from './registry_mixins'

import CKEditor from '@ckeditor/ckeditor5-vue'
import InlineEditor from '@/components/HTMLEditor/ckeditor.js'
export default {
  components: {
    ckeditor: CKEditor.component
  },
  name: 'text_field',
  mixins: [mixin, registryMixin],
  props: {
    name: {
      type: String,
      frozen: true
    },
    label: {
      description: 'Название',
      type: String
    },
    placeholder: {
      description: 'Плейсхолдер',
      type: String
    },
    tooltip: {
      description: 'Подсказка',
      type: String
    },
    required: {
      description: 'Обязательное',
      type: Boolean
    },
    minRows: {
      description: 'Строк в высоту',
      type: String,
      default: '2'
    },
    richEditor: {
      description: 'Ричедитор',
      type: Boolean
    },
    height: {
      description: 'Высота ричедитора',
      type: String
    }
  },
  watch: {
    localModel (value) {
      this.$emit('input', value)
    }
  },
  data () {
    return {
      localModel: this.value,
      editor: InlineEditor
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  }
}
</script>

<style>
  .quill-editor .ql-container.ql-snow {
    height: calc(100% - 58px);
  }
</style>
